// @nebular theming framework
@import '~@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '~@nebular/theme/styles/themes';



// which themes you what to enable (empty to enable all)
$nb-enabled-themes: (default, custom, light, dark);
$primary: #5f9ea0;

:root {
  --primary: #{$primary};
}

$nb-themes: nb-register-theme((
 // app wise variables for each theme
  sidebar-header-gap: 2rem,
  sidebar-header-height: initial,
  layout-content-width: 1400px,

  font-main: Roboto,
  font-secondary: Roboto,

  color-primary: $primary,
  card-fg-heading: $primary,

  sidebar-bg: #4f5f81,
  sidebar-fg: #ffffff,

  menu-bg: #4f5f81,
  menu-fg: #edeef8,
  menu-icon-color: #edeef8,
  menu-icon-active-color: #ffffff,

  menu-active-fg: #ffffff,
  menu-active-bg: $primary,

  menu-submenu-fg: #edeef8,
  menu-submenu-bg: #4f5f81,
  menu-submenu-hover-bg: $primary,
  menu-submenu-hover-fg: $primary,
  menu-submenu-active-border-color: $primary,
  menu-submenu-active-bg: $primary,
  menu-submenu-active-fg: #ffffff,

  menu-item-separator: #4f5f81,

  switcher-background: $primary,
  switcher-background-percentage: 50%,
  drops-icon-line-gadient: -webkit-linear-gradient(#01dbb5, #0bbb79),
), default, default);

$nb-themes: nb-register-theme((
 // app wise variables for each theme
  sidebar-header-gap: 2rem,
  sidebar-header-height: initial,
  layout-content-width: 1400px,

  font-main: Roboto,
  font-secondary: Roboto,

  color-primary: $primary,
  card-fg-heading: var(--primary),

  sidebar-bg: #263238,
  sidebar-fg: #ffffff,

  menu-bg: #37474f,
  menu-fg: #c5cae9,
  menu-icon-color: #c5cae9,
  menu-icon-active-color: #ffffff,

  menu-active-fg: #ffffff,
  menu-active-bg: var(--primary),

  menu-submenu-fg: #c5cae9,
  menu-submenu-bg: #37474f,
  menu-submenu-hover-bg: var(--primary),
  menu-submenu-hover-fg: var(--primary),
  menu-submenu-active-border-color: var(--primary),
  menu-submenu-active-bg: var(--primary),
  menu-submenu-active-fg: #ffffff,

  menu-item-separator: #37474f,

  switcher-background: var(--primary),
  switcher-background-percentage: 50%,
  drops-icon-line-gadient: -webkit-linear-gradient(#01dbb5, #0bbb79),
), custom, default);

$nb-themes: nb-register-theme((


  color-primary: $primary,
  card-fg-heading: $primary,

  sidebar-bg: #ffffff,
  sidebar-fg: #2a2a2a,

  menu-bg: #ffffff,
  menu-fg: #4b4b4b,
  menu-icon-color: #c5cae9,
  menu-icon-active-color: #ffffff,

  menu-active-fg: #ffffff,
  menu-active-bg: $primary,

  menu-submenu-fg: #4b4b4b,
  menu-submenu-bg: #ffffff,
  menu-submenu-hover-bg: $primary,
  menu-submenu-hover-fg: #ffffff,
  menu-submenu-active-border-color: $primary,
  menu-submenu-active-bg: $primary,
  menu-submenu-active-fg: #ffffff,

  menu-item-separator: #ffffff,

  switcher-background: #f2f2f2,
  switcher-background-percentage: 50%,
  drops-icon-line-gadient: -webkit-linear-gradient(#01dbb5, #0bbb79),
), light, default);

$nb-themes: nb-register-theme((
 
  color-bg: #263238,
  color-fg: #ffffff,
  color-fg-text: #ffffff,
  color-fg-heading: $primary,
  layout-bg: #303030,
  switcher-background: #37474f,

  smart-table-bg-even: #37474f,

  shadow: 0 1px 2px 0 #000000,


  sidebar-header-gap: 2rem,
  sidebar-header-height: initial,
  layout-content-width: 1400px,

  font-main: Roboto,
  font-secondary: Roboto,

  color-primary: $primary,

  sidebar-bg: #263238,
  sidebar-fg: #ffffff,

  menu-bg: #37474f,
  menu-fg: #c5cae9,
  menu-icon-color: #c5cae9,
  menu-icon-active-color: #ffffff,

  menu-active-fg: #ffffff,
  menu-active-bg: $primary,

  menu-submenu-fg: #c5cae9,
  menu-submenu-bg: #37474f,
  menu-submenu-hover-bg: $primary,
  menu-submenu-hover-fg: #ffffff,
  menu-submenu-active-border-color: $primary,
  menu-submenu-active-bg: $primary,
  menu-submenu-active-fg: #ffffff,

  menu-item-separator: #37474f,

  switcher-background-percentage: 50%,
  drops-icon-line-gadient: -webkit-linear-gradient(#01dbb5, #0bbb79),
), dark, default);